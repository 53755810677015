import React, { useState } from "react";

import { WhisperAi } from "@/interfaces/whisper_ai";
import { User } from "@/interfaces/user";
import AiCard from "@/components/biz/partials/whisper_ais/AiCard";

type Props = {
  title: string;
  whisperAis: WhisperAi[];
  user: User;
};

const AiMenuTextJobRole: React.FC<Props> = (props) => {
  return (
    <>
      <p className="text-sm text-left font-bold border-b py-2 pl-3 mb-10 bg-gray-100">
        {props.title}
      </p>
      <div className="text-sm mb-6 sm:px-8 px-3">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {props.whisperAis.map((ai) => {
            return (
              <AiCard key={`ai-${ai.slug}`} whisperAi={ai} user={props.user} />
            );
          })}
        </div>
      </div>
    </>
  );
};
export default AiMenuTextJobRole;
