import React, { useState, useEffect, useMemo } from "react";

import { createConsumer } from "@rails/actioncable";
import { WhisperAi } from "../../../interfaces/whisper_ai";
import { Ai } from "../../../interfaces/ai";
import { InputField } from "../../../interfaces/input_field";
import { MainCategory } from "../../../interfaces/main_category";
import { SubCategory } from "../../../interfaces/sub_category";
import { LeafCategory } from "../../../interfaces/leaf_category";

import { transcribeMovie } from "../../../libs/api/users/whisper";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import MovieUploadForm from "../../users/ais/whisper/MovieUploadForm";
import PromptSettingForm from "../../users/ais/chat/PromptSettingForm";
import AiMessage from "../../users/ais/chat/messages/Ai";

type Props = {
  whisperAi: WhisperAi;
  mainCategories: MainCategory[];
  textAis: Ai[];
  getSubCategories: (params: {
    mainCategorySlug: string;
  }) => Promise<{ data: SubCategory[] }>;
  getLeafCategories: (params: {
    subCategorySlug: string;
  }) => Promise<{ data: LeafCategory[] }>;
  updateWhisperAi: (
    slug: string,
    formData: FormData
  ) => Promise<{ data: WhisperAi }>;
  createWhisperAi: (formData: FormData) => Promise<{ data: WhisperAi }>;
  bulkPostInputFields: (params: {
    inputFields: InputField[];
    whisperAiId: string;
    deletedIds: string[];
  }) => Promise<{ data: InputField[] }>;
};

const AdminsAisEdit: React.FC<Props> = (props) => {
  console.log("props.whisperAi.ai", props.whisperAi.ai);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [leafCategories, setLeafCategories] = useState<LeafCategory[]>([]);
  const [editImage, setEditImage] = useState(
    props.whisperAi.image?.url == null
  );
  const [uploading, setUploading] = useState(false);
  const [transcribeText, setTranscribeText] = useState(null);
  const [streaming, setStreaming] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [message, setMessage] = useState("");

  const cable = useMemo(() => createConsumer(), []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: props.whisperAi.name,
      intro: props.whisperAi.intro,
      image: "",
      mainCategorySlug: props.whisperAi.mainCategorySlug,
      subCategorySlug: props.whisperAi.subCategorySlug,
      leafCategorySlug: props.whisperAi.leafCategorySlug,
      prompt: props.whisperAi.prompt,
      orderIdx: props.whisperAi.orderIdx,
      aiId: props.whisperAi.aiId,
      status: props.whisperAi.status,
      slug: props.whisperAi.slug,
      ctaText: props.whisperAi.ctaText,
      toB: props.whisperAi.toB,
    },
  });

  useEffect(() => {
    if (props.whisperAi.mainCategorySlug != null) {
      props
        .getSubCategories({
          mainCategorySlug: props.whisperAi.mainCategorySlug,
        })
        .then(({ data }) => {
          setSubCategories(data);
        });
    }
  }, [props.whisperAi.mainCategorySlug]);

  useEffect(() => {
    if (props.whisperAi.subCategorySlug != null) {
      props
        .getLeafCategories({
          subCategorySlug: props.whisperAi.subCategorySlug,
        })
        .then(({ data }) => {
          setLeafCategories(data);
        });
    }
  }, [props.whisperAi.subCategorySlug]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("whisper_ai[name]", data.name || "");
    formData.append("whisper_ai[intro]", data.intro || "");
    if (data.image != undefined && data.image) {
      formData.append("whisper_ai[image]", data.image);
    }

    formData.append(
      "whisper_ai[mainCategorySlug]",
      data.mainCategorySlug || ""
    );
    formData.append("whisper_ai[subCategorySlug]", data.subCategorySlug || "");
    formData.append(
      "whisper_ai[leafCategorySlug]",
      data.leafCategorySlug || ""
    );
    formData.append("whisper_ai[prompt]", data.prompt || "");
    formData.append("whisper_ai[orderIdx]", data.orderIdx || "");
    formData.append("whisper_ai[status]", data.status || "");
    formData.append("whisper_ai[aiId]", data.aiId || "");
    formData.append("whisper_ai[toB]", data.toB || "");

    if (data.slug != null) {
      formData.append("whisper_ai[slug]", data.slug || "");
    }
    formData.append("whisper_ai[ctaText]", data.ctaText || "");
    try {
      if (props.whisperAi?.id != null) {
        await props.updateWhisperAi(props.whisperAi.slug, formData);
        toastSuccess("更新しました");
      } else {
        await props.createWhisperAi(formData);
        toastSuccess("作成しました");
      }
    } catch (error) {
      let errorMessage = error.response.data.errors.join(", ");
      toast.error(`更新に失敗しました。${errorMessage}`);
      console.error(error);
    }
  };
  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleFileChange = (event) => {
    setValue("image", event.target.files[0]);
  };

  const onChangeMainCategory = async (v: string) => {
    setSubCategories([]);
    setLeafCategories([]);
    let { data } = await props.getSubCategories({
      mainCategorySlug: v,
      kind: "beauty",
    });
    setSubCategories(data);
  };

  const onChangeSubCategory = async (v: string) => {
    setLeafCategories([]);
    let { data } = await props.getLeafCategories({
      subCategorySlug: v,
    });
    setLeafCategories(data);
  };

  const onSubmitMovieUploadForm = async (datas: any) => {
    setUploading(true);
    // FormData オブジェクトの作成
    const formData = new FormData();
    // "movieFile" というキーでファイルを追加
    formData.append("movieFile", datas["movieFile"]);
    formData.append("videoUrl", datas["videoUrl"]);

    // FormData を使用してリクエストを送信
    let { data } = await transcribeMovie(formData);
    setUploading(false);
    setTranscribeText(data.text);
    toast.success("動画を文字起こししました");
  };

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  };

  const onSubmitPromptSettingForm = async (data: any) => {
    setStreaming(true);
    data["transcribe_text"] = transcribeText;
    // ここでランダムの文字列生成
    const token = generateRandomString(20);
    const sub = cable.subscriptions.create(
      { channel: "PrivateChatChannel", token: token },
      {
        connected: () => {
          sub.perform("stream_message", {
            ai_slug: props.whisperAi.ai.slug,
            form_data: data,
            token: token,
          });
        },
        received: (res) => {
          if (res.error) {
            alert(res.error);
            setMessage("申し訳ございません、問題が発生しました");
          } else if (res.status) {
            if (res.status == "finish") {
              setStreaming(false);
            }
          } else {
            if (res?.data != "") {
              setMessage((prev) => prev + res?.data);
            }
          }
        },
      }
    );
    setSubscription(sub);
    setMessage("");
  };

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription) {
      subscription.unsubscribe();
    }
  };

  return (
    <>
      <div className="shadow sm:overflow-hidden sm:rounded-md border">
        <form className="simple_form edit_ai" onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6">
            <div>
              <div className="mb-4 string required ai_name">
                <label
                  className="block string required text-sm font-medium text-gray-600"
                  htmlFor="ai_name"
                >
                  名称
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ml-2">
                    *必須
                  </span>
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 string required"
                  type="text"
                  id="ai_name"
                  {...register("name", { required: true })}
                />
                {errors.name?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            <div>
              {editImage ? (
                <>
                  <div className="mb-4 file required ai_image">
                    <label
                      className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                      htmlFor="ai_image"
                    >
                      アイコン画像
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ml-2">
                        *必須
                      </span>
                    </label>
                    <div className="grid grid-cols-12 mt-1">
                      <input
                        className="col-span-10 w-full text-gray-500 border rounded file required"
                        type="file"
                        name="ai[image]"
                        id="ai_image"
                        onChange={handleFileChange}
                      />
                      <button
                        type="button"
                        className="ml-2 w-24 rounded-md bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setEditImage(false)}
                      >
                        キャンセル
                      </button>
                    </div>
                    {errors.image?.type === "required" && (
                      <p className="font-semibold text-sm text-red-500">
                        入力してください
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <label
                    className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                    htmlFor="ai_image"
                  >
                    アイコン画像
                  </label>
                  <div className="flex mt-1 items-center mb-4">
                    <img
                      src={props.whisperAi.image.url}
                      className="w-20 mr-2 flex-shrink-0 rounded-full bg-gray-300"
                    />
                    <button
                      type="button"
                      className="ml-2 w-24 rounded-md bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => setEditImage(true)}
                    >
                      変更
                    </button>
                  </div>
                </>
              )}
            </div>
            <div>
              <div className="mb-4 text optional ai_intro">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_intro"
                >
                  説明文
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={10}
                  id="ai_intro"
                  {...register("intro")}
                ></textarea>
              </div>
            </div>

            <div>
              <div className="mb-4 select required ai_main_category">
                <label
                  className="block select required text-sm font-medium text-gray-600"
                  htmlFor="ai_id"
                >
                  テキスト生成AI
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ml-2">
                    *必須
                  </span>
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select required"
                  id="ai_id"
                  {...register("aiId", { required: true })}
                >
                  <option key={`ai-id-0`} value="">
                    選択してください
                  </option>
                  {props.textAis.map((ai, i) => {
                    return (
                      <option key={`ai-id-${i}`} value={ai.id}>
                        {ai.name}
                      </option>
                    );
                  })}
                </select>
                {errors.aiId?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>

            <div>
              <div className="mb-4 select required ai_main_category">
                <label
                  className="block select required text-sm font-medium text-gray-600"
                  htmlFor="ai_main_category_id"
                >
                  大カテゴリ
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ml-2">
                    *必須
                  </span>
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select required"
                  id="ai_main_category_id"
                  {...register("mainCategorySlug", { required: true })}
                  onChange={(e) => onChangeMainCategory(e.target.value)}
                >
                  {props.mainCategories.map((c, i) => {
                    return (
                      <option key={`main-category-${i}`} value={c.slug}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
                {errors.mainCategorySlug?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            {subCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_sub_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_sub_category_id"
                  >
                    中カテゴリ
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_sub_category_id"
                    {...register("subCategorySlug")}
                    onChange={(e) => onChangeSubCategory(e.target.value)}
                  >
                    {subCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`sub-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}
            {leafCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_leaf_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_leaf_category_id"
                  ></label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_leaf_category_id"
                    {...register("leafCategorySlug")}
                  >
                    {leafCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`leaf-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}

            <div>
              <div className="mb-4 integer optional ai_order_idx">
                <label
                  className="block integer optional text-sm font-medium text-gray-600"
                  htmlFor="ai_order_idx"
                >
                  並び順
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric integer optional"
                  type="number"
                  step="1"
                  id="ai_order_idx"
                  {...register(`orderIdx`)}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 enum optional ai_status">
                <label
                  className="block enum optional text-sm font-medium text-gray-600"
                  htmlFor="ai_status"
                >
                  公開ステータス
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   enum optional form-control"
                  id="ai_status"
                  {...register(`status`)}
                >
                  <option value="draft">下書き</option>
                  <option value="published">公開済み</option>
                </select>
              </div>
            </div>

            <div>
              <div className="mb-4 string optional cta_text">
                <label
                  className="block string optional text-sm font-medium text-gray-600"
                  htmlFor="cta_text"
                >
                  CTAテキスト
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   string optional"
                  type="text"
                  id="cta_text"
                  placeholder={`生成する`}
                  {...register(`ctaText`)}
                />
              </div>
            </div>

            <div>
              <div className="mb-4 string optional ai_slug">
                <label
                  className="block string optional text-sm font-medium text-gray-600"
                  htmlFor="ai_slug"
                >
                  スラグ
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   string optional"
                  type="text"
                  id="ai_slug"
                  {...register(`slug`)}
                />
              </div>
            </div>

            <div>
              <div className="relative flex items-start mb-4">
                <div className="flex h-6 items-center">
                  <input
                    id="toBFlag"
                    aria-describedby="tob-description"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    {...register(`toB`)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="toBFlag"
                    className="font-medium text-gray-900"
                  >
                    toB
                  </label>
                  <p id="tob-description" className="text-gray-500">
                    toBで利用可能にします
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <input
              type="submit"
              name="commit"
              value="送信する"
              className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            />
          </div>
        </form>
      </div>
      {props.whisperAi.id != null && (
        <>
          <div className="py-3 mt-5">
            <h2 className="text-xl font-bold">Preview</h2>
          </div>
          <div className="shadow sm:overflow-hidden sm:roudned-md border">
            <MovieUploadForm
              submit={(datas) => onSubmitMovieUploadForm(datas)}
              uploading={uploading}
              remainGenerateCap={100}
            />
          </div>

          {transcribeText != null && (
            <>
              <div className="p-4 mt-4 rounded-lg w-full inline-block rounded-tl-none text-gray-800 whitespace-pre-wrap break-words">
                <AiMessage
                  message={transcribeText}
                  ai={props.whisperAi.ai}
                  streaming={false}
                  showAction={true}
                  hideActionEditor={true}
                />
              </div>
              <PromptSettingForm
                submit={(datas) => onSubmitPromptSettingForm(datas)}
                aiForms={inputFields}
                streaming={streaming}
                cancelStreaming={cancelStreaming}
                isAdmin={true}
              />
            </>
          )}
          {message != "" && (
            <>
              <div className="py-3 mt-5">
                <h2 className="text-xl font-bold">出力結果</h2>
              </div>
              <div className="shadow sm:overflow-hidden sm:roudned-md border p-5 pb-10">
                <AiMessage
                  message={message}
                  ai={props.whisperAi.ai}
                  streaming={streaming}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default AdminsAisEdit;
