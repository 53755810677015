import React from "react";

import { WhisperAi } from "@/interfaces/whisper_ai";
import { User } from "../../../../interfaces/user";

type Props = {
  whisperAi: WhisperAi;
  user?: User;
};

const UsersWhisperAi: React.FC<Props> = (props) => {
  return (
    <>
      <div className="relative h-full">
        <a
          className="border p-5 flex items-top align-items-start rounded-lg z-10 h-[130px]"
          href={`/users/whisper_ais/${props.whisperAi.slug}`}
        >
          <div className="flex items-center justify-center">
            <div className="bg-gray-100 rounded-lg text-primary w-16 h-16 flex items-center justify-center text-xl mr-3">
              <img
                alt=""
                className="object-cover w-16 h-16 flex-shrink-0 rounded bg-gray-300"
                src={props.whisperAi.image.thumb.url}
              />
            </div>
          </div>
          <div className="w-full py-3">
            <h3 className="text-[15px] font-bold mb-2 line-clamp-2 break-all">
              {props.whisperAi.name}
            </h3>
            <p className="text-sm text-gray-500 line-clamp-2 break-all">
              {props.whisperAi.intro}
            </p>
          </div>
        </a>
      </div>
    </>
  );
};
export default UsersWhisperAi;
